import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EventDefinition } from '../../shared/interfaces/events.interface';

const basePath = '/v1/event_definitions';

@Injectable({
  providedIn: 'root',
})
export class EventDefinitionService {
  constructor(private http: HttpClient) { }

   // TODO: Estoy recibiendo los event_definition del dealer y del dealer padre, es correcto que así sea?
    getEventDefinitions(): Observable<EventDefinition[]> {
      return this.http.get<EventDefinition[]>(`${basePath}`);
    }

    // getEventDefinitionById(definitionId: number): Observable<EventDefinition> {
    //   return this.http.get<EventDefinition>(`${basePath}/${definitionId}`);
    // }

    // TODO: definir formato del body
    createEventDefinition(body: any) {
      return this.http.post(`${basePath}`, body);
    }

    updateEventDefinition(body: any) {
      return this.http.put(`${basePath}`, body);
    }

    deleteEventDefinition(definitionId: number) {
      return this.http.delete(`${basePath}/${definitionId}`);
    }

}
