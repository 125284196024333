<mat-toolbar>
  <span>{{currentFeature$ | async}}</span>
  <span class="spacer"></span>
  <button mat-button [matMenuTriggerFor]="dealersMenu">Organización: {{currentUserDealerName$ | async}}</button>
  <mat-menu #dealersMenu>
    <button mat-menu-item *ngFor="let dealer of currentUserDealers$ |async" (click)="changeUserDealer(dealer.users_dealers_id)"> {{dealer.dealer_name}}</button>
  </mat-menu>
  <!-- TODO: agregar botones de perfil de usuari, etc. -->
  <button mat-icon-button>
    <mat-icon>share</mat-icon>
  </button>
</mat-toolbar>