import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigResponse } from '../../shared/interfaces/equipment.interfaces';
import { Observable } from 'rxjs';

const basePath = '/v1/config';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  constructor(private http: HttpClient) { }

  getConfig(id: string | number): Observable<ConfigResponse[]> {
    return this.http.get<ConfigResponse[]>(`${basePath}/${id}`);
  }

  // TODO: typing
  postConfig(sn: string | number, command: string, userId: any) {
    const body = {
      command: command,
      uid: userId,
    };
    return this.http.post(`${basePath}/${sn}`, body);
  }

}