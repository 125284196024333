import { createReducer, on } from '@ngrx/store';
import { EventDefinition, EventProtocol, Events } from '../../../shared/interfaces/events.interface';
import { EventActions } from './event.actions';

export const eventFeatureKey = 'event';

export interface EventState {
  isLoading: boolean;
  error: unknown;
  events: Events | null;
  eventProtocols: EventProtocol[] | null;
  eventDefinitions: EventDefinition[] | null;
}

export const initialState: EventState = {
  isLoading: false,
  error: null,
  events: null,
  eventProtocols: null,
  eventDefinitions: null
};

export const eventReducer = createReducer(
  initialState,
  on(EventActions.loadEvents, state => { return { ...state, isLoading: true } }),
  on(EventActions.loadEventsSuccess, (state, action) => { return { ...state, isLoading: false, error: false, events: action.data } }),
  on(EventActions.loadEventsFailure, (state, action) => { return { ...state, isLoading: false, error: action.error } }),

  on(EventActions.loadEventProtocols, state => { return { ...state, isLoading: true } }),
  on(EventActions.loadEventProtocolsSuccess, (state, action) => { return { ...state, isLoading: false, error: false, eventProtocols: action.data } }),
  on(EventActions.loadEventProtocolsFailure, (state, action) => { return { ...state, isLoading: false, error: action.error } }),

  on(EventActions.loadEventDefinitions, state => { return { ...state, isLoading: true } }),
  on(EventActions.loadEventDefinitionsSuccess, (state, action) => { return { ...state, isLoading: false, error: false, eventDefintions: action.data } }),
  on(EventActions.loadEventDefinitionsFailure, (state, action) => { return { ...state, isLoading: false, error: action.error } }),

  on(EventActions.unsetEventStore, () => initialState)
);
