import { ApplicationConfig, isDevMode, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { provideEffects } from '@ngrx/effects';
import { rootReducer, metaReducers, rehydrateStateFromLocalStorage } from './core/stores/store';
import { HTTP_INTERCEPTORS, provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import { UserEffects } from './core/stores/user/user.effects';
import { httpInterceptor } from './core/helpers/http.interceptor';
import { DealerEffects } from './core/stores/dealer/dealer.effects';
import { EquipmentEffects } from './core/stores/equipment/equipment.effects';
import { DashboardEffects } from './core/stores/dashboard/dashboard.effects';
import { AccountsEffects } from './core/stores/account/account.effects';
import { EventEffects } from './core/stores/event/event.effects';

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    provideAnimationsAsync(),
    provideStore(rootReducer, {
      metaReducers,
      initialState: rehydrateStateFromLocalStorage()
    }),
    provideEffects(
      [UserEffects, DashboardEffects, EquipmentEffects, AccountsEffects, DealerEffects, EventEffects]
    ),
    provideStoreDevtools({
      maxAge: 25,
      logOnly: !isDevMode()
    }),
    provideHttpClient(
      withFetch(),
      withInterceptorsFromDi()
    ),
    { provide: HTTP_INTERCEPTORS, useClass: httpInterceptor, multi: true }
  ]
};
