import { Component, OnDestroy, OnInit } from '@angular/core';
import { LayoutComponent } from '../layout/layout.component';
import { Router, RouterModule} from '@angular/router';
import { Store } from '@ngrx/store';
import { RootState } from '../../core/stores/store';
import { LayoutActions } from '../../core/stores/layout/layout.actions';
import { UserActions } from '../../core/stores/user/user.actions';
import { DealerActions } from '../../core/stores/dealer/dealer.actions';
import { EquipmentActions } from '../../core/stores/equipment/equipment.actions';
import { User } from '../../shared/interfaces/user.interface';
import { selectCurrentUser } from '../../core/stores/user/user.selectors';
import { DashboardActions } from '../../core/stores/dashboard/dashboard.actions';
import { Observable } from 'rxjs';
import { AccountsActions } from '../../core/stores/account/account.actions';

@Component({
    selector: 'app-main',
    imports: [
        LayoutComponent,
        RouterModule
    ],
    templateUrl: './main.component.html',
    styleUrl: './main.component.scss'
})
export class MainComponent implements OnInit, OnDestroy {

  currentUser$: Observable<User | null>;

  constructor(
    private store: Store<RootState>,
    private router: Router
  ) {
    this.currentUser$ = this.store.select(selectCurrentUser)
  }

  ngOnInit(): void {
    this.currentUser$.subscribe(user => {
      if (!user) {
        this.router.navigate(['/login']);
      } else {
        this.store.dispatch(DealerActions.loadDealer());
      }
    });
  }

  ngOnDestroy(): void {
    this.store.dispatch(UserActions.unsetUser())
    this.store.dispatch(LayoutActions.unsetMainFeature());
    this.store.dispatch(DashboardActions.unsetDashboard());
    this.store.dispatch(EquipmentActions.unsetEquipmentStore());
    this.store.dispatch(AccountsActions.unsetAccounts());
    this.store.dispatch(DealerActions.unsetDealer());
  }

}
