import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LogItem } from '../../shared/interfaces/log.interface';

const basePath = '/v1/logs';

@Injectable({
  providedIn: 'root'
})
export class LogService {

  constructor(private http: HttpClient) { }

  getActivityLog(sn: number | string): Observable<LogItem[]> {
    return this.http.get<LogItem[]>(`${basePath}?sn=${sn}`);
  }

}