import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  Account,
  AllAccounts
} from '../../../shared/interfaces/accounts.interfaces';
import { Status } from '../../../shared/interfaces/status.interface';

const basePath = '/v1/account';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  constructor(private http: HttpClient) { }

  // getFiltered(filter: any): Observable<AllAccounts> {
  //   let params = '';
  //   for (let prop in filter) {
  //     const value = filter[prop];
  //     if (
  //       typeof value === 'number' ||
  //       typeof value === 'boolean' ||
  //       (typeof value === 'string' && value.length > 0)
  //     ) {
  //       const p = `${prop}=${filter[prop]}`;
  //       if (params.length > 0) {
  //         params += `&${p}`;
  //       } else {
  //         params += `?${p}`;
  //       }
  //     }
  //   }
  //   return this.http.get<AllAccounts>(`${basePath}${params}`);
  // }

   // TODO: Esta función es provisional:
    getFilteredAccounts(filter: any): Observable<AllAccounts> {
      return this.http.get<AllAccounts>(`${basePath}?filters={"global":{"value":"${filter}","matchMode":"contains"}}`);
    }

  getAccountById(id: string | number): Observable<Account> {
    return this.http.get<Account>(`${basePath}/${id}`);
  }

  createAccount(accountName: string, accountNumber: string ): Observable<Status> {
    return this.http.post<Status>(basePath, { name: accountName, number: accountNumber });
  }

  updateAccount(body: { accountId: number; accountName: string; accountNumber: string }) {
    return this.http.put(basePath, body);
  }

}
