import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

const basePath = '/v1/simcard';

@Injectable({
  providedIn: 'root'
})
export class SimcardService {

  constructor(private http: HttpClient) { }

  // TODO: typing
  updateSimcard(body: any) {
    return this.http.put(basePath, body);
  }

}