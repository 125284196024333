import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { DealerActions } from './dealer.actions';
import { Router } from '@angular/router';
import { DealerCandidateService } from '../../api-services/dealer/dealer_candidate.service';
import { DealerService } from '../../api-services/dealer/dealer.service';
import { DealerChildrenService } from '../../api-services/dealer/dealer_children.service';
import { DealerUserService } from '../../api-services/dealer/dealer_user.service';

@Injectable()
export class DealerEffects {

  private actions$ = inject(Actions);

  constructor(
    private dealerService: DealerService,
    private dealerCandidateService: DealerCandidateService,
    private dealerChildrenService: DealerChildrenService,
    private dealerUserService: DealerUserService,
    private router: Router
  ) { }

  loadDealer$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DealerActions.loadDealer),
      concatMap(() =>
        this.dealerService.getDealer().pipe(
          map(data => DealerActions.loadDealerSuccess({ data })),
          catchError(error => of(DealerActions.loadDealerFailure({ error }))))
      )
    );
  });

  loadUsers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DealerActions.loadUsers),
      concatMap(() =>
        this.dealerUserService.getAllUsers().pipe(
          map(data => DealerActions.loadUsersSuccess({ data })),
          catchError(error => of(DealerActions.loadUsersFailure({ error }))))
      )
    );
  });

  loadChildrenDealers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DealerActions.loadChildrenDealers),
      concatMap(() =>
        this.dealerChildrenService.getChildrenDealers().pipe(
          map(data => DealerActions.loadChildrenDealersSuccess({ data })),
          catchError(error => of(DealerActions.loadChildrenDealersFailure({ error }))))
      )
    );
  });

  sendNewDealerRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DealerActions.sendNewDealerRequest),
      concatMap((action) =>
        this.dealerCandidateService.newDealerRequest(action.payload).pipe(
          map(data => DealerActions.sendNewDealerRequestSuccess({ data })),
          catchError(error => of(DealerActions.sendNewDealerRequestFailure({ error })))
        ))
    );
  });
  reloadAfterSendNewDealerRequestSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DealerActions.sendNewDealerRequestSuccess),
      tap(() => this.router.navigate(['/login']))
    ),
    { dispatch: false }
  );

}
