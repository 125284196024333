import { inject, Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { UserService } from "../../api-services/user.service";
import { UserActions } from "./user.actions";
import { catchError, concatMap, map, of, tap } from "rxjs";
import { Router } from "@angular/router";

@Injectable()
export class UserEffects {

  private actions$ = inject(Actions);

  constructor(
    private userService: UserService,
    private router: Router
  ) { }

  setUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.setUser),
      concatMap((action) =>
        this.userService.authenticate(action.payload.usernameOrEmail, action.payload.password).pipe(
          map(data => UserActions.setUserSuccess({ data })),
          catchError(error => of(UserActions.setUserFailure({ error })))
        ))
    );
  });
  navigateAfterSetUserSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.setUserSuccess),
      tap(() => this.router.navigate(['/main']))
    ),
    { dispatch: false }
  );

  resetUserPassword$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.resetUserPassword),
      concatMap((action) =>
        this.userService.resetPassword(action.payload.data).pipe(
          map(data => UserActions.resetUserPasswordSuccess({ data })),
          catchError(error => of(UserActions.resetUserPasswordFailure({ error })))
        ))
    );
  });
  navigateAfterResetUserPasswordSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.resetUserPasswordSuccess),
      tap(() => this.router.navigate(['/login']))
    ),
    { dispatch: false }
  );

  loadDealers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.loadUserDealers),
      concatMap(() =>
        this.userService.getDealers().pipe(
          map(data => UserActions.loadUserDealersSuccess({ data })),
          catchError(error => of(UserActions.loadUserDealersFailure({ error })))
        ))
    );
  });

  changeUserDealer$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.changeUserDealer),
      concatMap((action) =>
        this.userService.changeDealer(action.payload.usersDealersId).pipe(
          map(data => UserActions.changeUserDealerSuccess({ data })),
          catchError(error => of(UserActions.changeUserDealerFailure({ error })))
        ))
    );
  });
  reloadAfterChangeUserDealerSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.changeUserDealerSuccess),
      tap(() => window.location.reload())
    ),
    { dispatch: false }
  );

}