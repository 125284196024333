import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Events } from '../../shared/interfaces/events.interface';

const basePath = '/v1/event';

@Injectable({
  providedIn: 'root',
})
export class EventService {
  constructor(private http: HttpClient) { }

   // TODO: Esta función es provisional, hay que hacerla con filtrado como corresponde:
    getEvents(): Observable<Events> {
      return this.http.get<Events>(`${basePath}`);
    }

}
