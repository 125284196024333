import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { EventActions } from './event.actions';
import { EventService } from '../../api-services/event.service';
import { EventProtocolService } from '../../api-services/event_protocol.service';
import { EventDefinitionService } from '../../api-services/event_definition.service';

@Injectable()
export class EventEffects {
  private actions$ = inject(Actions)

  constructor(
    private eventService: EventService,
    private eventProtocolService: EventProtocolService,
    private eventDefinitionService: EventDefinitionService
  ) { }

  loadEvents$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EventActions.loadEvents),
      concatMap(() =>
        this.eventService.getEvents().pipe(
          map(data => EventActions.loadEventsSuccess({ data })),
          catchError(error => of(EventActions.loadEventsFailure({ error })))
        )
      )
    );
  });

  loadEventProtocols$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EventActions.loadEventProtocols),
      concatMap(() =>
        this.eventProtocolService.getEventProtocols().pipe(
          map(data => EventActions.loadEventProtocolsSuccess({ data })),
          catchError(error => of(EventActions.loadEventProtocolsFailure({ error })))
        )
      )
    );
  });

    createEventProtocol$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(EventActions.createEventProtocol),
        concatMap((action) =>
          this.eventProtocolService.createEventProtocol(action.payload).pipe(
            switchMap(data => [
              EventActions.createEventProtocolSuccess({ data }),
              EventActions.loadEventProtocols()
            ]),
            catchError(error => of(EventActions.createEventProtocolFailure({ error })))
          )
        )
      );
    });

    updateEventProtocol$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(EventActions.updateEventProtocol),
        concatMap((action) =>
          this.eventProtocolService.updateEventProtocol(action.payload).pipe(
            switchMap(data => [
              EventActions.updateEventProtocolSuccess({ data }),
              EventActions.loadEventProtocols()
            ]),
            catchError(error => of(EventActions.updateEventProtocolFailure({ error })))
          )
        )
      );
    });

    deleteEventProtocol$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(EventActions.deleteEventProtocol),
        concatMap((action) =>
          this.eventProtocolService.deleteEventProtocol(action.protocolId).pipe(
            switchMap(data => [
              EventActions.deleteEventProtocolSuccess({ data }),
              EventActions.loadEventProtocols()
            ]),
            catchError(error => of(EventActions.deleteEventProtocolFailure({ error })))
          )
        )
      );
    });


  loadEventDefinitions$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EventActions.loadEventDefinitions),
      concatMap(() =>
        this.eventDefinitionService.getEventDefinitions().pipe(
          map(data => EventActions.loadEventDefinitionsSuccess({ data })),
          catchError(error => of(EventActions.loadEventDefinitionsFailure({ error })))
        )
      )
    );
  });

  createEventDefinition$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EventActions.createEventDefinition),
      concatMap((action) =>
        this.eventDefinitionService.createEventDefinition(action.payload).pipe(
          switchMap(data => [
            EventActions.createEventDefinitionSuccess({ data }),
            EventActions.loadEventDefinitions()
          ]),
          catchError(error => of(EventActions.createEventDefinitionFailure({ error })))
        )
      )
    );
  });

  updateEventDefinition$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EventActions.updateEventDefinition),
      concatMap((action) =>
        this.eventDefinitionService.updateEventDefinition(action.payload).pipe(
          switchMap(data => [
            EventActions.updateEventDefinitionSuccess({ data }),
            EventActions.loadEventProtocols()
          ]),
          catchError(error => of(EventActions.updateEventDefinitionFailure({ error })))
        )
      )
    );
  });

  deleteEventDefinition$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EventActions.deleteEventDefinition),
      concatMap((action) =>
        this.eventDefinitionService.deleteEventDefinition(action.definitionId).pipe(
          switchMap(data => [
            EventActions.deleteEventDefinitionSuccess({ data }),
            EventActions.loadEventDefinitions()
          ]),
          catchError(error => of(EventActions.deleteEventDefinitionFailure({ error })))
        )
      )
    );
  });
}
