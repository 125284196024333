import { Component, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { RootState } from '../../../../core/stores/store';
import { selectMainFeature } from '../../../../core/stores/layout/layout.selectors';
import { CommonModule } from '@angular/common';
import { UserActions } from '../../../../core/stores/user/user.actions';
import { MatMenuModule } from '@angular/material/menu';
import { selectCurrentUserDealerName, selectUserDealers } from '../../../../core/stores/user/user.selectors';
import { UserDealer } from '../../../../shared/interfaces/user.interface';
import { DashboardActions } from '../../../../core/stores/dashboard/dashboard.actions';
import { EquipmentActions } from '../../../../core/stores/equipment/equipment.actions';
import { DealerActions } from '../../../../core/stores/dealer/dealer.actions';
import { AccountsActions } from '../../../../core/stores/account/account.actions';

@Component({
    selector: 'app-toolbar',
    imports: [
        CommonModule,
        MatToolbarModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule
    ],
    templateUrl: './toolbar.component.html',
    styleUrl: './toolbar.component.scss'
})
export class ToolbarComponent implements OnInit {

  currentFeature$: Observable<string>;
  currentUserDealerName$: Observable<string | undefined>;
  currentUserDealers$: Observable<UserDealer[] | null>

  constructor(
    private store: Store<RootState>
  ) {
    this.currentFeature$ = this.store.select(selectMainFeature);
    this.currentUserDealerName$ = this.store.select(selectCurrentUserDealerName);
    this.currentUserDealers$ = this.store.select(selectUserDealers)
  }

  ngOnInit(): void {
    this.store.dispatch(UserActions.loadUserDealers());
  }

  changeUserDealer(usersDealersId: number): void {
    this.store.dispatch(DashboardActions.unsetDashboard());
    this.store.dispatch(EquipmentActions.unsetEquipmentStore());
    this.store.dispatch(DealerActions.unsetDealer());
    this.store.dispatch(AccountsActions.unsetAccounts());
    return this.store.dispatch(UserActions.changeUserDealer({ payload: { usersDealersId: usersDealersId } }))
  }

}
