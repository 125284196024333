<mat-sidenav-container autosize>
  <mat-sidenav class="sidenav" #sidenav [opened]="true" mode="side" [ngClass]="isCollapsed ? 'collapsed' : ''">

    <button class="toggle" mat-mini-fab (click)="toggleMenu()">
      <mat-icon>{{ isCollapsed ? 'chevron_right' : 'chevron_left' }}</mat-icon>
    </button>

    <!-- TODO: Faltan agregar más enlaces de navegación y componentes -->
    <mat-nav-list>
      <a mat-list-item routerLink="/main" routerLinkActive="active"> <mat-icon>dashboard</mat-icon> <span *ngIf="!isCollapsed">Dashboard</span></a>
      <a mat-list-item routerLink="/main/equipments" routerLinkActive="active"> <mat-icon>speaker_group</mat-icon> <span *ngIf="!isCollapsed">Equipos</span></a>
      <a mat-list-item routerLink="/main/dealers" routerLinkActive="active"> <mat-icon>domain</mat-icon> <span *ngIf="!isCollapsed">Organizaciones</span></a>
      <a mat-list-item routerLink="/main/accounts" routerLinkActive="active"> <mat-icon>admin_panel_settings</mat-icon> <span *ngIf="!isCollapsed">Cuentas</span></a>
      <a mat-list-item routerLink="/main/events" routerLinkActive="active"> <mat-icon>admin_panel_settings</mat-icon> <span *ngIf="!isCollapsed">Eventos</span></a>
      <a mat-list-item routerLink="/main/users" routerLinkActive="active"> <mat-icon>supervisor_account</mat-icon> <span *ngIf="!isCollapsed">Usuarios</span></a>
      <a mat-list-item routerLink="/login"> <mat-icon>logout</mat-icon> <span *ngIf="!isCollapsed">Salir</span></a>
    </mat-nav-list>

  </mat-sidenav>

  <mat-sidenav-content>
    <ng-content></ng-content>
  </mat-sidenav-content>

</mat-sidenav-container>