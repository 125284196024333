import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { environment } from "../../../environments/environment";
import { selectCurrentUser } from '../stores/user/user.selectors';
import { RootState } from '../stores/store';
import { User } from '../../shared/interfaces/user.interface';


// Este interceptor captura todos los httpRequests que se hacen hacia la api de Beat y les agrega el Bearer token y el dealer del usuario logueado

@Injectable()
export class httpInterceptor implements HttpInterceptor {

  private currentUser$: User | null = null;

  constructor(private store: Store<RootState>) {
    this.store.select(selectCurrentUser).subscribe((user) => {
      this.currentUser$ = user;
    });
  }

  intercept(req: HttpRequest<any>, handler: HttpHandler): Observable<HttpEvent<any>> {
    let authReq = req.clone({ url: environment.apiUrl + req.url });

    if (!this.currentUser$) {
      return handler.handle(authReq)
    } else {
      authReq = authReq.clone({
        setHeaders: {
          Authorization: `Bearer ${this.currentUser$.token}`,
          Dealer: JSON.stringify(this.currentUser$.dealer)
        }
      });
      return handler.handle(authReq)
    }
  }
}
