import { Routes } from '@angular/router';
import { MainComponent } from './features/main/main.component';
import { LoginComponent } from './features/login/login.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent,
    loadChildren: () => import('./features/login/login.routes').then(r => r.routes)
  },
  {
    path: 'main',
    component: MainComponent,
    loadChildren: () => import('./features/main/main.routes').then(r => r.routes)
  },
];
