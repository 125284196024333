<div class="flex-full-container">

  <!-- TODO: buscar una nueva buena imagen de presentacion, logo, dar estilo, etc -->

  <div class="half">
    <!-- mitad izquierda de la pantalla -->
    <img class="landing-img" src="assets/capture.jpg" alt="placeholder">
  </div>

  <div class="half right">
    <!-- mitad derecha de la pantalla -->

    <div>
      <!-- logo netio -->
    </div>

    <div>
      <!-- contenido principal -->
      <router-outlet />
    </div>

    <div>
      <!-- otras opciones y botones -->
    </div>

  </div>


</div>