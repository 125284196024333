import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ChildrenDealer, Dealer, DealerUser } from '../../../shared/interfaces/dealer.interfaces';

const basePath = '/v1/dealer';

@Injectable({
  providedIn: 'root'
})
export class DealerService {
  constructor(private http: HttpClient) { }

  getDealer(): Observable<Dealer[]> {
    return this.http.get<Dealer[]>(`${basePath}`);
  }

  // TODO: typing
  updateDealer(body: any) {
    return this.http.put(`${basePath}`, body);
  }

  // TODO: dealer/logo, está en el controlador de dealer

  // uploadLogo(formData: FormData) {
  //   return this.http.post(`${basePath}/logo`, formData);
  // }

}
