import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { Router, RouterModule } from '@angular/router';
import { Observable } from 'rxjs';
import { User } from '../../shared/interfaces/user.interface';
import { Store } from '@ngrx/store';
import { RootState } from '../../core/stores/store';
import { selectCurrentUser } from '../../core/stores/user/user.selectors';

@Component({
  selector: 'app-login',
  imports: [
    RouterModule,
    MatButtonModule
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent {

  currentUser$: Observable<User | null>;

  constructor(
    private store: Store<RootState>,
    private router: Router
  ) {
    this.currentUser$ = this.store.select(selectCurrentUser)
  }

  ngOnInit(): void {
    this.currentUser$.subscribe(user => {
      if (user) {
        this.router.navigate(['/main']);
      }
    });
  }
}
