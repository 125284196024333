import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { AccountsActions } from './account.actions';
import { AccountService } from '../../api-services/account/account.service';
import { AccountEquipmentService } from '../../api-services/account/account_equipment.service';

@Injectable()
export class AccountsEffects {
  private actions$ = inject(Actions)

  constructor(
    private accountService: AccountService,
    private accountEquipmentService: AccountEquipmentService
  ) { }

  loadAccounts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AccountsActions.loadAccounts),
      concatMap((action) =>
        this.accountService.getFilteredAccounts(action.payload).pipe(
          map(data => AccountsActions.loadAccountsSuccess({ data })),
          catchError(error => of(AccountsActions.loadAccountsFailure({ error })))
        )
      )
    );
  });

  loadAccountDetails$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AccountsActions.loadAccountDetails),
      concatMap((action) =>
        this.accountService.getAccountById(action.id).pipe(
          map(data => AccountsActions.loadAccountDetailsSuccess({ data })),
          catchError(error => of(AccountsActions.loadAccountDetailsFailure({ error })))
        )
      )
    );
  });

  createAccount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AccountsActions.createAccount),
      concatMap((action) =>
        this.accountService.createAccount(action.accountName, action.accountNumber).pipe(
          switchMap(data => [
            AccountsActions.createAccountSuccess({ data }),
            AccountsActions.loadAccounts({ payload: '' })
          ]),
          catchError(error => of(AccountsActions.createAccountFailure({ error })))
        )
      )
    );
  });

  linkEquipmentToAccount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AccountsActions.linkEquipmentToAccount),
      concatMap((action) =>
        this.accountEquipmentService.linkEquipmentToAccount({ accountId: action.payload.accountId, equipmentId: action.payload.accountId }).pipe(
          map(data =>
            AccountsActions.linkEquipmentToAccountSuccess({ data })
          ),
          catchError(error => of(AccountsActions.createAccountFailure({ error })))
        )
      )
    );
  });

  removeEquipmentFromAccount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AccountsActions.removeEquipmentFromAccount),
      concatMap((action) =>
        this.accountEquipmentService.removeEquipmentFromAccount(action.sn).pipe(
          map(data =>
            AccountsActions.removeEquipmentFromAccountSuccess({ data })
          ),
          catchError(error => of(AccountsActions.createAccountFailure({ error })))
        )
      )
    );
  });

}