import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap, tap, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { EquipmentActions } from './equipment.actions';
import { EquipmentService } from '../../api-services/equipment.service';
import { LogService } from '../../api-services/log.service';
import { SimcardService } from '../../api-services/simcard.service';
import { ConfigService } from '../../api-services/config.service';
import { Router } from '@angular/router';
import { ClaimService } from '../../api-services/claim.service';

@Injectable()
export class EquipmentEffects {
  private actions$ = inject(Actions)

  constructor(
    private equipmentService: EquipmentService,
    private logService: LogService,
    private configService: ConfigService,
    private simcardService: SimcardService,
    private claimService: ClaimService,
    private router: Router
  ) { }

  loadEquipments$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EquipmentActions.loadEquipments),
      concatMap((action) =>
        this.equipmentService.getFilteredEquipments(action.payload).pipe(
          map(data => EquipmentActions.loadEquipmentsSuccess({ data })),
          catchError(error => of(EquipmentActions.loadEquipmentsFailure({ error }))))
      )
    );
  });

  loadEquipmentDetails$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EquipmentActions.loadEquipmentDetails),
      concatMap((action) =>
        this.equipmentService.getEquipmentById(action.id).pipe(
          map(data => EquipmentActions.loadEquipmentDetailsSuccess({ data })),
          catchError(error => of(EquipmentActions.loadEquipmentDetailsFailure({ error }))))
      )
    );
  });

  updateEquipmentDetails$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EquipmentActions.updateEquipmentDetails),
      concatMap(({ payload }) =>
        this.equipmentService.updateEquipment(payload).pipe(
          switchMap(() => [
            EquipmentActions.updateEquipmentDetailsSuccess({ id: payload.id }),
            EquipmentActions.loadEquipmentDetails({ id: payload.id })
          ]),
          catchError(error => of(EquipmentActions.updateEquipmentDetailsFailure({ error })))
        )
      )
    );
  });

  updateEquipmentDealer$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EquipmentActions.updateEquipmentDealer),
      concatMap(({ payload }) =>
        this.equipmentService.updateEquipment(payload).pipe(
          map((data) => EquipmentActions.updateEquipmentDealerSuccess({ data })),
          catchError(error => of(EquipmentActions.updateEquipmentDealerFailure({ error }))))
      )
    );
  });

  loadEquipmentActivityLog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EquipmentActions.loadEquipmentActivityLog),
      concatMap((action) =>
        this.logService.getActivityLog(action.sn).pipe(
          map(data => EquipmentActions.loadEquipmentActivityLogSuccess({ data })),
          catchError(error => of(EquipmentActions.loadEquipmentActivityLogFailure({ error }))))
      )
    );
  });

  loadEquipmentConfiguration$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EquipmentActions.loadEquipmentConfiguration),
      concatMap((action) =>
        this.configService.getConfig(action.id).pipe(
          map(data => {
            // Transformar los datos en un objeto con claves y valores
            const unsortedConfig = data.reduce((acc, item) => {
              const pairs = item.config.value.split(';'); // Dividir cada string por ";"
              pairs.forEach(pair => {
                if (pair) { // Ignorar entradas vacías
                  const [key, value] = pair.split(':'); // Dividir cada par por ":"
                  acc[key] = value; // Agregar clave-valor al objeto acumulador
                }
              });
              return acc;
            }, {} as Record<string, string>);

            // Ordenar alfabéticamente las claves
            const sortedConfig = Object.keys(unsortedConfig)
              .sort() // Ordenar las claves
              .reduce((acc, key) => {
                acc[key] = unsortedConfig[key]; // Reconstruir el objeto ordenado
                return acc;
              }, {} as Record<string, string>);

            return EquipmentActions.loadEquipmentConfigurationSuccess({ data: sortedConfig });
          }),
          catchError(error => of(EquipmentActions.loadEquipmentConfigurationFailure({ error })))
        )
      )
    );
  });

  requestNTConfiguration$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EquipmentActions.requestNTConfiguration),
      concatMap((action) =>
        this.configService.postConfig(action.sn, '09', action.userID).pipe(
          map(data => EquipmentActions.requestNTConfigurationSuccess({ data })),
          catchError(error => of(EquipmentActions.requestNTConfigurationFailure({ error }))))
      )
    );
  });

  updateSimcard$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EquipmentActions.updateSimcard),
      concatMap((payload) =>
        this.simcardService.updateSimcard(payload).pipe(
          switchMap(() => [
            EquipmentActions.updateSimcardSuccess({ id: payload.id }),
            EquipmentActions.loadEquipmentDetails({ id: payload.id })
          ]),
          catchError(error => of(EquipmentActions.updateEquipmentDetailsFailure({ error })))
        )
      )
    );
  });

  removeEquipmentFromDealer$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EquipmentActions.removeEquipmentFromDealer),
      concatMap(({ payload }) =>
        this.equipmentService.removeEquipmentFromDealer(payload.id).pipe(
          switchMap((data) => [
            EquipmentActions.removeEquipmentFromDealerSuccess({ data }),
            EquipmentActions.loadEquipments({ payload: '' })
          ]),
          catchError(error => of(EquipmentActions.removeEquipmentFromDealerFailure({ error }))))
      )
    );
  });
  navigateAfterRemoveEquipmentFromDealer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EquipmentActions.removeEquipmentFromDealerSuccess),
      tap(() => {
        EquipmentActions.loadEquipments({ payload: '' });
        this.router.navigate(['/main/equip'])
      })
    ),
    { dispatch: false }
  );

    searchEquipment$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(EquipmentActions.searchEquipment),
        concatMap((action) =>
          this.claimService.searchEquipment(action.payload).pipe(
            map(data => EquipmentActions.searchEquipmentSuccess({ data })),
            catchError(error => of(EquipmentActions.searchEquipmentFailure({ error }))))
        )
      );
    });
}