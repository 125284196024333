import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

const basePath = '/v1/dealer';

@Injectable({
  providedIn: 'root'
})
export class DealerCandidateService {
  constructor(private http: HttpClient) { }

  // TODO: typing
  newDealerRequest(body: any) {
    return this.http.post(`${basePath}/candidate`, body);
  }
}
