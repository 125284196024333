import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DealerInfo, FirmwareInfo, OnlineInfo, SignalInfo } from '../../shared/interfaces/dashboard.interfaces';

const basePath = '/v1/dashboard';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  constructor(private http: HttpClient) { }

  getOnlineInfo(): Observable<OnlineInfo> {
    return this.http.get<OnlineInfo>(`${basePath}/online`);
  }

  getSignalInfo(): Observable<SignalInfo> {
    return this.http.get<SignalInfo>(`${basePath}/signal`);
  }

  getFirmwareInfo(): Observable<FirmwareInfo[]> {
    return this.http.get<FirmwareInfo[]>(`${basePath}/firmware`);
  }

  getDealerInfo(): Observable<DealerInfo[]> {
    return this.http.get<DealerInfo[]>(`${basePath}/dealer`);
  }

}
