import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

const basePath = '/v1/claim';

@Injectable({
  providedIn: 'root'
})
export class ClaimService {
  constructor(private http: HttpClient) { }

  // lo que la api espera como accountId es el número de cuenta, no el id de la cuenta
  searchEquipment(filter: any) {
    let query = '';
    for (let key in filter) {
      const value = filter[key];
      if (typeof value === 'number' || typeof value === 'boolean' || (typeof value === 'string' && value.length > 0)) {
        if (query.length > 0) {
          query += `&${key}=${filter[key]}`;
        } else {
          query += `?${key}=${filter[key]}`;
        }
      }
    }
    return this.http.get(`${basePath}${query}`);
  }

}
