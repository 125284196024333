import { createReducer, on } from "@ngrx/store";
import { LayoutActions } from "./layout.actions";

export const layoutFeatureKey = 'main'

export interface LayoutState {
  currentMainFeature: string;
}

const initialState: LayoutState = {
  currentMainFeature: 'Beat'
}

export const layoutReducer = createReducer(
  initialState,
  on(LayoutActions.setMainFeature, (state, action) => { return { ...state, currentMainFeature: action.payload, }; }),
  on(LayoutActions.unsetMainFeature, () => initialState),
)