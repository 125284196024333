import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DealerUser } from '../../../shared/interfaces/dealer.interfaces';

const basePath = '/v1/dealer';

// TODO: dealer/users inconsistencia en los plurales

@Injectable({
  providedIn: 'root'
})
export class DealerUserService {
  constructor(private http: HttpClient) { }

  getAllUsers(): Observable<DealerUser[]> {
    return this.http.get<DealerUser[]>(`${basePath}/users`);
  }

    // TODO: functions not implemented yet
  getUserById(userId: string) {
    return this.http.get(`${basePath}/users/${userId}`);
  }

  updateUserPermissions(id: number, permissions: string[]) {
    return this.http.put(`${basePath}/users/${id}`, { permissions });
  }

  removeUser(id: string) {
    return this.http.delete(`${basePath}/users/${id}`);
  }

}
