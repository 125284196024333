import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  EquipmentDetails,
  Equipments,
} from '../../shared/interfaces/equipment.interfaces';
import { Observable } from 'rxjs';

const basePath = '/v1/equipment';

@Injectable({
  providedIn: 'root',
})
export class EquipmentService {
  constructor(private http: HttpClient) {}

  // getFiltered(filter: any): Observable<Equipments> {
  //   let params = '';
  //   for (let prop in filter) {
  //     const value = filter[prop];
  //     if (
  //       typeof value === 'number' ||
  //       typeof value === 'boolean' ||
  //       (typeof value === 'string' && value.length > 0)
  //     ) {
  //       const p = `${prop}=${filter[prop]}`;
  //       if (params.length > 0) {
  //         params += `&${p}`;
  //       } else {
  //         params += `?${p}`;
  //       }
  //     }
  //   }
  //   return this.http.get<Equipments>(`${basePath}${params}`);
  // }

  // TODO: Esta función es provisional:
  getFilteredEquipments(filter: any): Observable<Equipments> {
    return this.http.get<Equipments>(`${basePath}?filters={"global":{"value":"${filter}","matchMode":"contains"}}`);
  }

  updateEquipment(body: any) {
    return this.http.put(basePath, body);
  }

  // equipsUpdateDealer(newDealer: number, equipIds: number[]) {
  //   return this.http.put(`${basePath}`, { equipIds, newDealer });
  // }

  // Liberar equipo al dealer padre:
  removeEquipmentFromDealer(id: number) {
    return this.http.delete(`${basePath}/${id}`);
  }

  getEquipmentById(id: string | number | null): Observable<EquipmentDetails> {
    return this.http.get<EquipmentDetails>(`${basePath}/${id}`);
  }
}
