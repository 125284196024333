import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User, UserDealer } from '../../shared/interfaces/user.interface';
import { Observable } from 'rxjs';

const basePath = '/v1/user';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private http: HttpClient
  ) { }

  authenticate(usernameOrEmail: string, password: string): Observable<User> {
    return this.http.post<User>(`${basePath}/authenticate`, { username: usernameOrEmail, password: password })
  }

  resetPassword(data: string): Observable<any> {
    return this.http.post<any>(`${basePath}/resetPassword`, { data });
  }

  // getById(id: string) {
  //   return this.http.get(`${basePath}/${id}`);
  // }

  // update(id: number, user: any) {
  //   return this.http.put(`${basePath}/${id}`, user);
  // }

  getDealers(): Observable<UserDealer[]> {
    return this.http.get<UserDealer[]>(`${basePath}/dealers`);
  }

  changeDealer(id: number): Observable<User> {
    return this.http.put<User>(`${basePath}/dealer/${id}`, {});
  }

}
