import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EventProtocol, Events } from '../../shared/interfaces/events.interface';

const basePath = '/v1/event_protocols';

@Injectable({
  providedIn: 'root',
})
export class EventProtocolService {
  constructor(private http: HttpClient) { }

   // TODO: Estoy recibiendo los event_protocols del dealer y del dealer padre, es correcto que así sea?
    getEventProtocols(): Observable<EventProtocol[]> {
      return this.http.get<EventProtocol[]>(`${basePath}`);
    }

    // getEventProtocolById(protocolId: number): Observable<EventProtocol> {
    //   return this.http.get<EventProtocol>(`${basePath}/${protocolId}`);
    // }

    // TODO: definir formato del body y tipado
    createEventProtocol(body: any) {
      return this.http.post(`${basePath}`, body);
    }

    updateEventProtocol(body: any) {
      return this.http.put(`${basePath}`, body);
    }

    deleteEventProtocol(protocolId: number) {
      return this.http.delete(`${basePath}/${protocolId}`);
    }

}
