import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

const basePath = '/v1/account';

@Injectable({
  providedIn: 'root',
})

export class AccountEquipmentService {
  constructor(private http: HttpClient) { }

  // TODO: por que el /all/ en el path?
  getAvailableEquipments(dealerId: string | number) {
    return this.http.get(`${basePath}/equipment/all/${dealerId}`);
  }

  linkEquipmentToAccount(body: { accountId: string | number, equipmentId: string | number}) {
    return this.http.put(`${basePath}/equipment`, { body });
  }

  removeEquipmentFromAccount(sn: string | number) {
    return this.http.delete(`${basePath}/equipment/${sn}`);
  }
}
