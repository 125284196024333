import { ActionReducer, ActionReducerMap, MetaReducer } from "@ngrx/store";
import { layoutFeatureKey, layoutReducer, LayoutState } from "./layout/layout.reducer";
import { userFeatureKey, userReducer, UserState } from "./user/user.reducer";
import { dealerFeatureKey, dealerReducer, DealerState } from "./dealer/dealer.reducer";
import { equipmentFeatureKey, equipmentReducer, EquipmentState } from "./equipment/equipment.reducer";
import { dashboardFeatureKey, dashboardReducer, DashboardState } from "./dashboard/dashboard.reducer";
import { accountsFeatureKey, accountsReducer, AccountsState } from "./account/account.reducer";
import { eventFeatureKey, eventReducer, EventState } from "./event/event.reducer";

export interface RootState {
  [accountsFeatureKey]: AccountsState,
  [dashboardFeatureKey]: DashboardState,
  [dealerFeatureKey]: DealerState,
  [equipmentFeatureKey]: EquipmentState,
  [layoutFeatureKey]: LayoutState,
  [userFeatureKey]: UserState,
  [eventFeatureKey]: EventState,
}

export const rootReducer: ActionReducerMap<RootState> = {
  [accountsFeatureKey]: accountsReducer,
  [dashboardFeatureKey]: dashboardReducer,
  [dealerFeatureKey]: dealerReducer,
  [equipmentFeatureKey]: equipmentReducer,
  [layoutFeatureKey]: layoutReducer,
  [userFeatureKey]: userReducer,
  [eventFeatureKey]: eventReducer
}

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return function (state, action) {
    const nextState = reducer(state, action);
    localStorage.setItem('appState', JSON.stringify(nextState));
    return nextState;
  };
}

export function rehydrateStateFromLocalStorage(): any {
  const storedState = localStorage.getItem('appState');
  return storedState ? JSON.parse(storedState) : undefined;
}

export const metaReducers: MetaReducer<any>[] = [localStorageSyncReducer];