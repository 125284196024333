import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { DashboardActions } from './dashboard.actions';
import { DashboardService } from '../../api-services/dashboard.service';

@Injectable()
export class DashboardEffects {

  private actions$ = inject(Actions);

  constructor(
    private dashboardService: DashboardService
  ) { }

  loadOnlineInfo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardActions.loadOnlineInfo),
      concatMap(() =>
        this.dashboardService.getOnlineInfo().pipe(
          map(data => DashboardActions.loadOnlineInfoSuccess({ data })),
          catchError(error => of(DashboardActions.loadOnlineInfoFailure({ error }))))
      )
    );
  });

  loadSignalInfo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardActions.loadSignalInfo),
      concatMap(() =>
        this.dashboardService.getSignalInfo().pipe(
          map(data => DashboardActions.loadSignalInfoSuccess({ data })),
          catchError(error => of(DashboardActions.loadSignalInfoFailure({ error }))))
      )
    );
  });

  loadFirmwareInfo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardActions.loadFirmwareInfo),
      concatMap(() =>
        this.dashboardService.getFirmwareInfo().pipe(
          map(data => DashboardActions.loadFirmwareInfoSuccess({ data })),
          catchError(error => of(DashboardActions.loadFirmwareInfoFailure({ error }))))
      )
    );
  });

  loadDealerInfo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardActions.loadDealerInfo),
      concatMap(() =>
        this.dashboardService.getDealerInfo().pipe(
          map(data => DashboardActions.loadDealerInfoSuccess({ data })),
          catchError(error => of(DashboardActions.loadDealerInfoFailure({ error }))))
      )
    );
  });

}
